/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateTeamRequestBody,
  CreateTeamResponseBody,
  EditTeamRequestBody,
  InviteTeamUsersRequestBody,
  InviteTeamUsersResponse,
  Team,
  TeamAuthority,
  TeamId,
  UpdateTeamMemberAuthoritiesRequestBody,
} from '@shared/types/team';
import { Observable } from 'rxjs';
import { WebSocketConnection } from './websocket-connection.service';

@Injectable({ providedIn: 'root' })
export class TeamsApiService {
  constructor(
    private http: HttpClient,
    private ws: WebSocketConnection,
  ) {}
  getTeamId(teamId: TeamId): Observable<TeamId> {
    return this.http.get<TeamId>(`/api/v1/teams/${teamId}/id`);
  }

  subscribeToTeams(): Observable<Team[]> {
    return this.ws.getTopic<Team[]>('/user/topic/teams');
  }
  removeUserFromTeam(teamId: TeamId, userEmail: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/teams/${teamId}/members/${userEmail}`);
  }
  editTeam(teamId: TeamId, changes: EditTeamRequestBody): Observable<void> {
    return this.http.patch<void>(`/api/v1/teams/${teamId}`, changes);
  }
  createTeam(params: CreateTeamRequestBody): Observable<CreateTeamResponseBody> {
    return this.http.put<CreateTeamResponseBody>('/api/v1/teams', params);
  }
  removeTeam(teamId: TeamId): Observable<void> {
    return this.http.delete<void>(`/api/v1/teams/${teamId}`);
  }
  editTeamMemberAuthorities(teamId: TeamId, email: string, authorities: TeamAuthority[]): Observable<void> {
    return this.http.put<void>(`/api/v1/teams/${teamId}/members/${email}/authorities`, {
      authorities,
    } as UpdateTeamMemberAuthoritiesRequestBody);
  }
  removeTeamMemberFromTeam(teamId: TeamId, email: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/teams/${teamId}/members/${email}`);
  }
  inviteUsersToTeam(teamId: TeamId, emails: string[]): Observable<InviteTeamUsersResponse> {
    return this.http.put<InviteTeamUsersResponse>(`/api/v1/teams/${teamId}/invite`, {
      names: emails,
    } as InviteTeamUsersRequestBody);
  }
}
